import React, { Component, Fragment } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, Badge, Button, Spinner } from "react-bootstrap";
import Cookies from "universal-cookie";
import ModalEmailVendor from "../components/Others/ModalEmailVendor";
import ModalEmailUBB from "../components/Others/ModalEmailUBB";
import ModalEmailVendorDataPosted from "../components/Others/ModalEmailVendorDataPosted";
import ModalEmailVendorChangeStatus from "../components/Others/ModalEmailVendorChangeStatus";
import { apiBaseUrlLocal } from "../includes/AppConfig";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const cookies = new Cookies();

class Shipments extends Component {
  state = {
    shipmentsList: [],
    showModal: false,
    showModalUBB: false,
    inProcessSubmitData: false,
    showModalPostedData: false,
    showModalUpdateShipmentStatus: false,
    isLoadingShipmentsList: false,
    selectedShipment: {},
    filtered: [],
    admin_data: cookies.get("admin_data"),
  };

  constructor() {
    super();
    this.loadShipments = this.loadShipments.bind(this);
    this.submitShipmentData = this.submitShipmentData.bind(this);
    this.updateShipmentStatus = this.updateShipmentStatus.bind(this);
    this.pushToAmazon = this.pushToAmazon.bind(this);
    this.onFilteredChangeCustom = this.onFilteredChangeCustom.bind(this);
    this.downloadListingCSV = this.downloadListingCSV.bind(this);
  }

  testCall(x) {
    console.log(x);
  }

  componentDidMount() {
    this.loadShipments();
  }

  async pushToAmazon(selectedShipment) {
    this.setState({ pushingToAmazon: true });

    await fetch(apiBaseUrlLocal + "admin/shipments/pushtoamazon", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_name: selectedShipment.name,
      }),
    });

    this.setState({ pushingToAmazon: false });
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  getUSFormattedDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  };

  async downloadListingCSV(selectedShipment) {
    this.setState({ downloadingCSV: true, downloadCSVShipmentName: selectedShipment.name });

    const response = await fetch(apiBaseUrlLocal + "admin/shipments/export", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_name: selectedShipment.name,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();

      if ("status" in data && data.status === true) {
        let csvContent = "data:text/csv;charset=utf-8,";
        console.log(data);

        // csvContent += "ASIN, SKU, QUANTITY, TITLE, CONDITION, CONDITION_NOTES, BUY_COST, SELF_WEAR, SOURCE, LISTING_PROFILE";

        csvContent +=
          (selectedShipment.name.toLowerCase().includes("rms")
            ? "ASIN,TITLE,PRICE,VENDOR,SCANNED ON"
            : selectedShipment.name.toLowerCase().includes("fba") || selectedShipment.name.toLowerCase().includes("mf")
            ? "ASIN,TITLE,TARGET_PROFIT,TARGET_PRICE,SCANNED ON"
            : "ASIN,TITLE,PRICE,SCANNED ON") + "\r\n";

        data.data.forEach(function (rowArray) {
          //console.log(rowArray);
          // let row =
          //   '"' +
          //   rowArray.asin +
          //   '",' +
          //   rowArray.sku +
          //   "," +
          //   rowArray.quantity +
          //   ',"' +
          //   rowArray.title +
          //   '",' +
          //   rowArray.condition +
          //   "," +
          //   rowArray.conditionNotes +
          //   "," +
          //   rowArray.buyCost +
          //   "," +
          //   rowArray.selfWear +
          //   "," +
          //   rowArray.source +
          //   "," +
          //   rowArray.listingProfileName;

          const scannedDate = new Date(rowArray.scanned_on);
          const yearScanned = scannedDate.getFullYear();
          let monthScanned = (1 + scannedDate.getMonth()).toString();
          monthScanned = monthScanned.length > 1 ? monthScanned : "0" + monthScanned;

          let dayScanned = scannedDate.getDate().toString();
          dayScanned = dayScanned.length > 1 ? dayScanned : "0" + dayScanned;

          //return month + "-" + day + "-" + year;

          let row =
            '="' +
            rowArray.asin +
            '","' +
            rowArray.title +
            '",' +
            (selectedShipment.name.toLowerCase().includes("fba")
              ? rowArray.fba_estimated_profit + "," + rowArray.fba_target_price
              : selectedShipment.name.toLowerCase().includes("mf")
              ? rowArray.mf_estimated_profit + "," + rowArray.mf_target_price
              : rowArray.price) +
            "," +
            (selectedShipment.name.toLowerCase().includes("rms") ? rowArray.hl_winner + "," : "") +
            (monthScanned + "-" + dayScanned + "-" + yearScanned);

          csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${selectedShipment.name}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".

        //console.log(data);
      }
    }
    this.setState({ downloadingCSV: false, downloadCSVShipmentName: null });
  }

  async loadShipments() {
    this.setState({ isLoadingShipmentsList: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/list", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ shipmentsList: data.data });
    }
    this.setState({ isLoadingShipmentsList: false });
  }

  async updateShipmentStatus(shipmentStatus, amountPaid) {
    this.setState({ isLoadingShipmentsList: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/update_status", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_status: shipmentStatus,
        amount_paid: amountPaid,
        shipment_id: this.state.selectedShipment.id,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true)
        this.setState({ shipmentsList: data.data, showModalUpdateShipmentStatus: false });
    }
    this.setState({ isLoadingShipmentsList: false });
  }

  async submitShipmentData(shipmentData) {
    this.setState({ inProcessSubmitData: true });
    const response = await fetch(apiBaseUrlLocal + "admin/shipments/submit", {
      method: "POST",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
        shipment_data: JSON.stringify(shipmentData),
        shipment_id: this.state.selectedShipment.id,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ shipmentsList: data.data, showModal: false });
    }
    this.setState({ inProcessSubmitData: false, showModal: false, showModalUBB: false });
  }

  async deleteShipment(shipmentData) {
    this.setState({ isLoadingShipmentsList: true });
    const response = await fetch(`${apiBaseUrlLocal}admin/shipments/${shipmentData.id}`, {
      method: "DELETE",
      body: new URLSearchParams({
        user_id: this.state.admin_data.admin_id,
        app_token: this.state.admin_data.token,
      }),
    });
    if (response.status === 200) {
      const data = await response.json();
      if ("status" in data && data.status === true) this.setState({ shipmentsList: data.data });
    }
    this.setState({ isLoadingShipmentsList: false });
  }

  handleDeleteShipment(shipmentData) {
    confirmAlert({
      title: "Delete Shipment?",
      message: "This will permanently unmap items associated with this Shipment. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteShipment(shipmentData),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <Fragment>
        <TopMenu
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/shipments"
        />

        <ModalEmailVendor
          submitShipmentData={this.submitShipmentData}
          showModal={this.state.showModal}
          handleClose={() => this.setState({ showModal: false })}
          selectedShipment={this.state.selectedShipment}
        />
        <ModalEmailUBB
          inProcessSubmitData={this.state.inProcessSubmitData}
          submitShipmentData={this.submitShipmentData}
          showModal={this.state.showModalUBB}
          handleClose={() => this.setState({ showModalUBB: false })}
          selectedShipment={this.state.selectedShipment}
        />

        <ModalEmailVendorDataPosted
          showModal={this.state.showModalPostedData}
          displayName={this.state.selectedShipment.display_name}
          handleClose={() => this.setState({ showModalPostedData: false })}
          dataPosted={
            "submit_data" in this.state.selectedShipment &&
            this.state.selectedShipment.submit_data !== null &&
            this.state.selectedShipment.submit_data !== ""
              ? JSON.parse(this.state.selectedShipment.submit_data)
              : {}
          }
        />
        <ModalEmailVendorChangeStatus
          updateShipmentStatus={this.updateShipmentStatus}
          showModal={this.state.showModalUpdateShipmentStatus}
          handleClose={() => this.setState({ showModalUpdateShipmentStatus: false })}
          selectedShipment={this.state.selectedShipment}
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <h2>Shipments</h2>
          <Row style={{ marginTop: "20px", paddingBottom: "20px" }}>
            <Col md={12}>
              <ReactTable
                showPagination={false}
                // defaultPageSize={10}
                pageSize={-1}
                data={this.state.shipmentsList}
                filterable
                filtered={this.state.filtered}
                onFilteredChange={(filtered, column, value) => {
                  this.onFilteredChangeCustom(value, column.id || column.accessor);
                }}
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id;
                  if (typeof filter.value === "object") {
                    return row[id] !== undefined ? filter.value.indexOf(row[id]) > -1 : true;
                  } else {
                    return row[id] !== undefined ? String(row[id]).indexOf(filter.value) > -1 : true;
                  }
                }}
                columns={[
                  {
                    Header: "Shipment Details",
                    columns: [
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Shipment Name
                          </div>
                        ),
                        accessor: "name",
                        style: { "text-align": "center" },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Destination
                          </div>
                        ),
                        accessor: "display_name",
                        style: { "text-align": "center" },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Created On
                          </div>
                        ),
                        id: "createdOn",
                        style: { "text-align": "center" },
                        filterable: false,
                        accessor: (d) => this.getUSFormattedDate(d.created_on),
                      },
                    ],
                  },
                  {
                    Header: "Info",
                    columns: [
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            Items
                          </div>
                        ),
                        accessor: "item_count",
                        style: { "text-align": "right" },
                        filterable: false,
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                            Potential Profit
                          </div>
                        ),
                        id: "potentialProfit",
                        style: { "text-align": "right" },
                        filterable: false,
                        accessor: (d) =>
                          d.potential_profit !== "" && d.potential_profit !== null ? d.potential_profit.toFixed(2) : 0,
                        Cell: ({ value }) => <span>${value}</span>,
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Status
                          </div>
                        ),
                        id: "status",
                        style: { "text-align": "center" },
                        accessor: (d) =>
                          d.status === "PAID"
                            ? `PAID $${d.amount_paid.toFixed(2)} ON ${new Date(d.paid_on).toISOString().slice(0, 10)}`
                            : d.status === "SHIPPED"
                            ? `SHIPPED ON ${new Date(d.shipped_on).toISOString().slice(0, 10)}`
                            : `OPEN`,
                        Cell: ({ value }) =>
                          value === "OPEN" ? (
                            <Badge variant="primary">{value}</Badge>
                          ) : value.includes("SHIPPED") ? (
                            <Badge variant="warning">{value}</Badge>
                          ) : (
                            <Badge variant="success">{value}</Badge>
                          ),
                        filterMethod: (filter, row) => {
                          if (filter.value.indexOf("all") > -1) return true;
                          if (filter.value.indexOf("open") > -1) return row[filter.id] === "OPEN";
                          if (filter.value.indexOf("shipped") > -1) return row[filter.id].indexOf("SHIPPED") > -1;
                          if (filter.value.indexOf("paid") > -1) return row[filter.id].indexOf("PAID") > -1;
                        },
                        Filter: ({ filter, onChange }) => {
                          return (
                            <select
                              onChange={(event) => {
                                let selectedOptions = [].slice.call(event.target.selectedOptions).map((o) => {
                                  return o.value;
                                });

                                this.onFilteredChangeCustom(selectedOptions, "status");
                              }}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : ["all"]}
                              multiple={true}
                            >
                              <option value="all">All</option>
                              <option value="open">Open</option>
                              <option value="shipped">Shipped</option>
                              <option value="paid">Paid</option>
                            </select>
                          );
                        },
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Actions
                          </div>
                        ),
                        id: "actions",
                        style: { "white-space": "unset" },
                        filterable: false,
                        Cell: (cellInfo) => {
                          return (
                            <div>
                              {cellInfo.original.submit_data !== null ? (
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="sm"
                                  title="Posted Data"
                                  variant="success"
                                  onClick={() => {
                                    this.setState({ showModalPostedData: true, selectedShipment: cellInfo.original });
                                  }}
                                >
                                  <i style={{ color: "white" }} className="fa fa-envelope-open" />
                                </Button>
                              ) : (cellInfo.original.display_name === "UBB" ||
                                  cellInfo.original.display_name === "RMS" ||
                                  cellInfo.original.display_name === "SBYB") &&
                                cellInfo.original.item_count > 0 ? (
                                <Button
                                  style={{ marginRight: "10px" }}
                                  size="sm"
                                  variant="dark"
                                  title="Email Vendor"
                                  onClick={() => {
                                    if (cellInfo.original.item_count === 0) alert("No ASIN in this shipments");
                                    else
                                      this.setState({
                                        [cellInfo.original.display_name === "UBB" ? `showModalUBB` : `showModal`]: true,
                                        selectedShipment: cellInfo.original,
                                      });
                                  }}
                                >
                                  <i style={{ color: "white" }} className="fa fa-envelope" />
                                </Button>
                              ) : (
                                <span></span>
                              )}

                              {(cellInfo.original.amount_paid === null || cellInfo.original.amount_paid === "") && (
                                <Button
                                  size="sm"
                                  title="Update Status"
                                  variant="primary"
                                  style={{ marginRight: "10px" }}
                                  onClick={() => {
                                    this.setState({
                                      showModalUpdateShipmentStatus: true,
                                      selectedShipment: cellInfo.original,
                                    });
                                  }}
                                >
                                  <i style={{ color: "white" }} className="fa fa-check-square" />
                                </Button>
                              )}

                              {cellInfo.original.item_count > 0 && (
                                <Button
                                  title="Download CSV"
                                  size="sm"
                                  style={{ marginRight: "10px" }}
                                  variant="dark"
                                  onClick={() => {
                                    this.downloadListingCSV(cellInfo.original);
                                  }}
                                >
                                  {this.state.downloadingCSV === true &&
                                  this.state.downloadCSVShipmentName === cellInfo.original.name ? (
                                    <Spinner
                                      animation="border"
                                      role="status"
                                      style={{ width: "15px", height: "15px" }}
                                    ></Spinner>
                                  ) : (
                                    <i style={{ color: "white" }} className="fa fa-cloud-download" />
                                  )}
                                </Button>
                              )}

                              <Button
                                title="Delete Shipment"
                                size="sm"
                                // style={{ marginRight: "20px", width: "180px", marginLeft: "20px" }}
                                variant="danger"
                                onClick={() => {
                                  this.handleDeleteShipment(cellInfo.original);
                                }}
                              >
                                <i style={{ color: "white" }} className="fa fa-close" />
                              </Button>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Shipments;

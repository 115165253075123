import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import auth from "../Auth";

class TopMenu extends Component {
  state = {
    domainName: window.location.hostname,
  };

  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  render() {
    return (
      <Navbar bg="dark" expand="lg" variant="dark">
        <Navbar.Brand href="/dashboard">
          {this.state.domainName === "admin.palletiq.com" || this.state.domainName === "admin-dev.palletiq.com"
            ? "PalletIQ"
            : this.state.domainName === "admin.myemptyshelves.com" || this.state.domainName === "admin.emptyshelves.com"
            ? "EmptyShelves"
            : this.state.domainName === "admin.bookteriors.com"
            ? "BOOKTERIORS"
            : "MBB"}{" "}
          Admin
        </Navbar.Brand>
        <Nav className="mr-auto" activeKey={this.props.activeKey}>
          <Nav.Link href="/triggers">Triggers</Nav.Link>
          <Nav.Link href="/users">Users</Nav.Link>
          <Nav.Link href="/sounds">Sounds</Nav.Link>
          <Nav.Link href="/scans">Scans</Nav.Link>
          <Nav.Link href="/shipping-plans">Shipping Plans</Nav.Link>
          <Nav.Link href="/shipments">Shipments</Nav.Link>
          {"isReevaluate" in this.props && this.props.isReevaluate === true && (
            <Nav.Link href="/reevaluate-queue">Reevaluate-Queue</Nav.Link>
          )}
          {"access_level" in this.props.adminData && this.props.adminData.access_level === 0 && (
            <Nav.Link href="/invite">Invite Users</Nav.Link>
          )}
        </Nav>

        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav activeKey={this.props.activeKey}>
            {"sources" in this.props && this.props.sources !== undefined && this.props.sources.length >= 1 && (
              <NavDropdown title="Sources" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => this.props.fetchStats("")}>ALL</NavDropdown.Item>
                <NavDropdown.Divider />
                {this.props.sources.map((source) => {
                  return (
                    <NavDropdown.Item onClick={() => this.props.fetchStats(source.source)} href="">
                      {source.source}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
            )}
            <Nav.Item>
              <Nav.Link href="/account-settings">Account</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopMenu;

import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";
import {
  loadReEvaluateQueue,
  saveReEvaluateQueue,
  updateReEvaluateQueue,
  scheduleForNow,
  getTriggerBatches,
  deleteReEvaluateQueue,
} from "../includes/HelperReEvaluateQueue";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ModalNewQueue from "../components/ReEvaluateQueue/NewQueue";
import ModalEditQueue from "../components/ReEvaluateQueue/EditQueue";
import { Row, Col, Button, Spinner, Container, Table, Alert } from "react-bootstrap";

const cookies = new Cookies();

class ReEvaluateQueue extends Component {
  state = {
    showAddModal: false,
    showEditModal: false,
    loadingQueue: false,
    isProcessingApi: false,
    scheduleNowInProcess: false,
    showSuccessAlert: false,
    successMessage: "",
    triggerBatches: [],
    currentEditQueue: {},
    currentIndexScheduleNow: -1,
    admin_data: cookies.get("admin_data"),
    reevaluateQueues: [],
  };

  constructor() {
    super();
    this.fetchReEvaluateQueue = this.fetchReEvaluateQueue.bind(this);
    this.fetchTriggerBatches = this.fetchTriggerBatches.bind(this);
    this.onFormSubmitAddQueue = this.onFormSubmitAddQueue.bind(this);
    this.onFormSubmitEditQueue = this.onFormSubmitEditQueue.bind(this);
    this.handleEditShow = this.handleEditShow.bind(this);
    this.handleRunNow = this.handleRunNow.bind(this);
  }

  componentDidMount() {
    this.fetchReEvaluateQueue();
    this.fetchTriggerBatches();
  }

  async onFormSubmitAddQueue(reevaluateQueueName, reevaluateTriggerBatchId, reevaluateFrequency, reevaluateQueueData) {
    this.setState({ isProcessingApi: true });
    const response = await saveReEvaluateQueue(
      this.state.admin_data,
      reevaluateQueueName,
      reevaluateQueueData,
      reevaluateTriggerBatchId,
      reevaluateFrequency
    );
    response !== false && response.status === true && this.setState({ reevaluateQueues: response.data });
    this.setState({ isProcessingApi: false, showAddModal: false });
  }

  async onFormSubmitEditQueue(reevaluateQueueName, reevaluateTriggerBatchId, reevaluateFrequency, reevaluateQueueData) {
    this.setState({ isProcessingApi: true });
    const response = await updateReEvaluateQueue(
      this.state.admin_data,
      this.state.currentEditQueue.id,
      reevaluateQueueName,
      reevaluateQueueData,
      reevaluateTriggerBatchId,
      reevaluateFrequency
    );
    response !== false && response.status === true && this.setState({ reevaluateQueues: response.data });
    this.setState({ isProcessingApi: false, showEditModal: false });
  }

  async deleteReEvaluateQueue(reevaluateQueue, index) {
    this.setState({ isProcessingApi: true });
    const response = await deleteReEvaluateQueue(this.state.admin_data, reevaluateQueue.id);
    response !== false && response.status === true && this.setState({ reevaluateQueues: response.data });
    this.setState({ isProcessingApi: false });
  }

  async fetchReEvaluateQueue() {
    this.setState({ loadingQueue: true });
    const response = await loadReEvaluateQueue(this.state.admin_data);
    response !== false && response.status === true && this.setState({ reevaluateQueues: response.data });
    this.setState({ loadingQueue: false });
  }

  async fetchTriggerBatches() {
    const response = await getTriggerBatches(this.state.admin_data);
    response !== false && this.setState({ triggerBatches: response });
  }

  handleEditShow(reevaluateQueue, _index) {
    this.setState({ currentEditQueue: reevaluateQueue, showEditModal: true });
  }

  async handleRunNow(reevaluateQueue, index) {
    this.setState({ scheduleNowInProcess: true, currentIndexScheduleNow: index });
    await scheduleForNow(reevaluateQueue.id, this.state.admin_data);
    this.setState({
      scheduleNowInProcess: false,
      showSuccessAlert: true,
      successMessage: `Queue ${reevaluateQueue.queue_name} scheduled to run immediately. You'll receive an email once it finishes execution.`,
    });
  }

  handleDeleteReEvaluateQueue(reevaluateQueue, index) {
    confirmAlert({
      title: `Delete ${reevaluateQueue.queue_name}`,
      message: "This will permanently delete this queue. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteReEvaluateQueue(reevaluateQueue, index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  render() {
    return (
      <React.Fragment>
        <ModalNewQueue
          showAddModal={this.state.showAddModal}
          handleClose={() => this.setState({ showAddModal: false })}
          isProcessingApi={this.state.isProcessingApi}
          triggerBatches={this.state.triggerBatches}
          onFormSubmitAddQueue={this.onFormSubmitAddQueue}
          editQueue={this.state.currentEditQueue}
        />
        <ModalEditQueue
          showEditModal={this.state.showEditModal}
          currentEditQueue={this.state.currentEditQueue}
          isProcessingApi={this.state.isProcessingApi}
          triggerBatches={this.state.triggerBatches}
          handleClose={() => this.setState({ showEditModal: false })}
          onFormSubmitEditQueue={this.onFormSubmitEditQueue}
        />

        <TopMenu
          isReevaluate={
            "is_re_evaluate" in this.state.admin_data && this.state.admin_data.is_re_evaluate === 1 ? true : false
          }
          history={this.props.history}
          userData={this.state.admin_data}
          activeKey="/reevaluate-queue"
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          {this.state.showSuccessAlert === true ? (
            <div>
              <Alert onClose={() => this.setState({ showSuccessAlert: false })} variant="success" dismissible>
                {this.state.successMessage}
              </Alert>
            </div>
          ) : (
            ""
          )}

          <Row>
            <Col md={4}>
              <h2>Re-evaluation Queue</h2>
            </Col>

            <Col md={8}>
              <div style={{ textAlign: "right", marginTop: "5px" }}>
                <Button
                  className="actionButtonLessPadding"
                  style={{ marginLeft: "8px" }}
                  variant="success"
                  onClick={() => this.setState({ showAddModal: true })}
                >
                  Add New Evaluation Queue
                </Button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th>Queue Name</th>
                    <th>Frequency</th>
                    <th>Last Executed</th>
                    <th>Trigger Batch</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loadingPlans === true ? (
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (
                    this.state.reevaluateQueues.map((reevaluateQueue, _index) => {
                      return (
                        <tr key={reevaluateQueue.id}>
                          <td>{reevaluateQueue.queue_name}</td>

                          <td>
                            {reevaluateQueue.frequency === "everyday"
                              ? "Every Day"
                              : reevaluateQueue.frequency === "everysat"
                              ? "Every Saturday"
                              : reevaluateQueue.frequency === "everytueandsat"
                              ? "Every Tuesday & Saturday"
                              : reevaluateQueue.frequency === "alternatesat"
                              ? "Alternate Saturday"
                              : "1st of Every Month"}
                          </td>
                          <td>
                            {reevaluateQueue.last_executed !== null && reevaluateQueue.last_executed !== ""
                              ? reevaluateQueue.last_executed
                              : "N/A"}
                          </td>
                          <td>{reevaluateQueue.trigger_batch_name}</td>
                          <td>
                            <Button
                              className="actionButtonLessPadding"
                              variant="success"
                              onClick={() => this.handleRunNow(reevaluateQueue, _index)}
                            >
                              {this.state.scheduleNowInProcess === true &&
                              _index === this.state.currentIndexScheduleNow ? (
                                <div style={{ width: "74px" }}>
                                  <Spinner
                                    style={{ width: "20px", height: "20px" }}
                                    animation="border"
                                    role="status"
                                  ></Spinner>
                                </div>
                              ) : (
                                " Run Now"
                              )}
                            </Button>{" "}
                            <Button
                              className="actionButtonLessPadding"
                              variant="light"
                              onClick={() => this.handleEditShow(reevaluateQueue, _index)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              className="actionButtonLessPadding"
                              style={{ marginLeft: "8px" }}
                              variant="danger"
                              onClick={() => this.handleDeleteReEvaluateQueue(reevaluateQueue, _index)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ReEvaluateQueue;

import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import Cookies from "universal-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Container, Row, Col, Table, Spinner, Button } from "react-bootstrap";
import ModalNewPlan from "../components/ShippingPlans/NewPlan";
import ModalEditPlan from "../components/ShippingPlans/EditPlan";
import {
  addShippingPlan,
  loadShippingPlans,
  editShippingPlan,
  deleteShippingPlan,
} from "../includes/HelperShippingPlans";

const cookies = new Cookies();

class ShippingPlans extends Component {
  state = {
    showAddModal: false,
    showEditModal: false,
    shippingPlans: [],
    loadingPlans: false,
    currentEditPlan: {},
    isProcessingApi: false,
    admin_data: cookies.get("admin_data"),
  };

  constructor() {
    super();
    this.onFormSubmitAddShippingPlan = this.onFormSubmitAddShippingPlan.bind(this);
    this.onFormSubmitEditShippingPlan = this.onFormSubmitEditShippingPlan.bind(this);
    this.fetchShippingPlans = this.fetchShippingPlans.bind(this);
  }

  componentDidMount() {
    this.fetchShippingPlans();
  }

  handleEditShow(shippingPlan, _index) {
    this.setState({ currentEditPlan: shippingPlan, showEditModal: true });
  }

  async fetchShippingPlans() {
    this.setState({ loadingPlans: true });
    const response = await loadShippingPlans(this.state.admin_data);
    console.log(response);
    response !== false && response.status === true && this.setState({ shippingPlans: response.data });
    this.setState({ loadingPlans: false });
  }

  async onFormSubmitAddShippingPlan(shippingPlanName, shippingPlanData) {
    // Call API to add trigger
    this.setState({ isProcessingApi: true });
    const response = await addShippingPlan(this.state.admin_data, shippingPlanName, shippingPlanData);
    response !== false && response.status === true && this.setState({ shippingPlans: response.data });
    this.setState({ showAddModal: false, isProcessingApi: false });
  }

  async onFormSubmitEditShippingPlan(shippingPlanName, shippingPlanData) {
    // Call API to add trigger
    this.setState({ isProcessingApi: true });
    const response = await editShippingPlan(
      this.state.admin_data,
      this.state.currentEditPlan.id,
      shippingPlanName,
      shippingPlanData
    );
    response !== false && response.status === true && this.setState({ shippingPlans: response.data });
    this.setState({ showEditModal: false, isProcessingApi: false });
  }

  handleDeleteShippingPlan(shippingPlan, _index) {
    confirmAlert({
      title: "Sure",
      message: "This will permanently delete this shipping plan. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.onDeleteShippingPlan(shippingPlan, _index),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }

  async onDeleteShippingPlan(shippingPlan, _index) {
    const response = await deleteShippingPlan(this.state.admin_data, shippingPlan.id);
    response !== false && response.status === true && this.setState({ shippingPlans: response.data });
  }

  render() {
    return (
      <React.Fragment>
        <ModalNewPlan
          showAddModal={this.state.showAddModal}
          handleClose={() => this.setState({ showAddModal: false })}
          isProcessingApi={this.state.isProcessingApi}
          onFormSubmitAddShippingPlan={this.onFormSubmitAddShippingPlan}
          editPlan={this.state.editPlan}
        />
        <ModalEditPlan
          showEditModal={this.state.showEditModal}
          currentEditPlan={this.state.currentEditPlan}
          isProcessingApi={this.state.isProcessingApi}
          handleClose={() => this.setState({ showEditModal: false })}
          onFormSubmitEditShippingPlan={this.onFormSubmitEditShippingPlan}
        />

        <TopMenu history={this.props.history} userData={this.state.admin_data} activeKey="/shipping-plans" />

        <Container fluid={true} style={{ paddingTop: "25px" }}>
          <Row>
            <Col md={3}></Col>

            <Col md={9}>
              <div style={{ textAlign: "right", marginTop: "5px" }}>
                <Button
                  className="actionButtonLessPadding"
                  style={{ marginLeft: "8px" }}
                  variant="success"
                  onClick={() => this.setState({ showAddModal: true })}
                >
                  Add New Shipping Plan
                </Button>
              </div>
            </Col>
          </Row>

          <Row style={{ paddingTop: "20px" }}>
            <Col md={12}>
              <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    <th>Last Updated</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.loadingPlans === true ? (
                    <tr>
                      <td colSpan={3} style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : (
                    this.state.shippingPlans.length >= 1 &&
                    this.state.shippingPlans.map((shippingPlan, _index) => {
                      const dateTimeJS = new Date(shippingPlan.updated_on);
                      return (
                        <tr key={shippingPlan.id}>
                          <td>{shippingPlan.plan_name}</td>
                          <td colSpan={shippingPlan.plan_name === "Media Mail" ? 2 : 1}>{dateTimeJS.toUTCString()}</td>

                          {shippingPlan.plan_name !== "Media Mail" && (
                            <td style={{ width: "13%" }}>
                              {(shippingPlan.is_public === 0 ||
                                (shippingPlan.is_public === 1 && this.state.admin_data.access_level === 0)) && (
                                <Button
                                  className="actionButtonLessPadding"
                                  variant="light"
                                  onClick={() => this.handleEditShow(shippingPlan, _index)}
                                >
                                  Edit
                                </Button>
                              )}
                              <Button
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="danger"
                                onClick={() => this.handleDeleteShippingPlan(shippingPlan, _index)}
                              >
                                Delete
                              </Button>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ShippingPlans;

import { apiBaseUrlLocal } from "./AppConfig";

export const sendInvitation = async (teamEmail, teamName, admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/invite", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      team_email: teamEmail,
      team_name: teamName,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data;
  } else return false;
};

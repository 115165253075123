import React, { Component, Fragment } from "react";
import { Form, Modal, Button, FormControl, Spinner, Table } from "react-bootstrap";

class ModalEmailVendor extends Component {
  state = {
    containerType: "boxes",
    numberOfContainers: 1,
  };

  initiateModal() {
    this.setState({ containerType: "boxes", numberOfContainers: 1 });
  }

  constructor() {
    super();
    this.onToggleContainerType = this.onToggleContainerType.bind(this);
    this.initiateModal = this.initiateModal.bind(this);
    this.submitEmailVendor = this.submitEmailVendor.bind(this);
  }

  onToggleContainerType(e) {
    this.setState({ containerType: e.target.value });
  }

  submitEmailVendor(e) {
    e.preventDefault();

    const containerType = e.target.elements.containerType.value;
    const numberOfContainers = e.target.elements.numberOfContainers.value;
    const boxWeightsElements = e.target.elements["boxWeights[]"];
    let boxWeights = [];
    boxWeightsElements.forEach((bwe) => {
      boxWeights.push(bwe.value);
    });

    let contactName = "",
      contactPhone = "",
      addressForPickup = "",
      addressType = "",
      needALiftgate = "",
      availableHoursStart = "",
      availableHoursEnd = "",
      availableDate = "",
      emailForLabels = "";

    if (containerType === "pallets") {
      contactName = e.target.elements.contactName.value;
      contactPhone = e.target.elements.contactPhone.value;
      addressForPickup = e.target.elements.addressForPickup.value;
      addressType = e.target.elements.addressType.value;
      needALiftgate = e.target.elements.needALiftgate.value;
      availableHoursStart = e.target.elements.availableHoursStart.value;
      availableHoursEnd = e.target.elements.availableHoursEnd.value;
      availableDate = e.target.elements.availableDate.value;
    } else {
      emailForLabels = e.target.elements.emailForLabels.value;
    }

    const formData = {
      containerType,
      numberOfContainers,
      boxWeights,
      contactName,
      contactPhone,
      addressForPickup,
      addressType,
      needALiftgate,
      availableHoursStart,
      availableHoursEnd,
      availableDate,
      emailForLabels,
    };

    this.props.submitShipmentData(formData);
  }

  render() {
    return (
      <Modal
        onEnter={this.initiateModal}
        show={this.props.showModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Send Email to {this.props.selectedShipment.display_name}</h3>
        </Modal.Header>

        <Modal.Body>
          <Form inline onSubmit={this.submitEmailVendor}>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Container Type</td>
                  <td style={{ paddingLeft: "20px" }}>
                    <FormControl required as="select" onChange={this.onToggleContainerType} name="containerType">
                      <option value="boxes">Boxes</option>
                      <option value="pallets">Pallets</option>
                    </FormControl>
                  </td>
                </tr>

                <tr>
                  <td>
                    Number of {this.state.containerType.charAt(0).toUpperCase() + this.state.containerType.slice(1)}
                  </td>
                  <td style={{ paddingLeft: "20px" }}>
                    <Form.Control
                      required
                      maxLength={3}
                      style={{ width: "100px" }}
                      type="numbers"
                      name="numberOfContainers"
                      onChange={(e) => {
                        this.setState({ numberOfContainers: e.target.value });
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    {this.state.containerType.charAt(0).toUpperCase() + this.state.containerType.slice(1)}' Weights
                  </td>
                  <td style={{ paddingLeft: "20px" }} colSpan={3}>
                    {Array.from({ length: this.state.numberOfContainers }, (_, k) => (
                      <FormControl
                        required
                        style={{ width: "80px", marginRight: "5px" }}
                        key={k}
                        inline
                        name="boxWeights[]"
                        type="numbers"
                        placeholder={`${this.state.containerType === "boxes" ? "Box" : "Pallet"} ${k + 1}`}
                      ></FormControl>
                    ))}
                  </td>
                </tr>

                {this.state.containerType === "boxes" ? (
                  <tr>
                    <td>Email Address</td>
                    <td style={{ paddingLeft: "20px" }}>
                      {" "}
                      <FormControl required name="emailForLabels" inline type="text"></FormControl>
                    </td>
                  </tr>
                ) : (
                  <Fragment>
                    <tr>
                      <td>Contact Name</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="contactName" inline type="text"></FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>Contact Phone</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="contactPhone" inline type="text"></FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>Address for Pickup</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <Form.Control required name="addressForPickup" as="textarea" rows="3" />
                      </td>
                    </tr>
                    <tr>
                      <td>Address Type</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="addressType" as="select">
                          <option value="residential">Residential</option>
                          <option value="commercial">Commercial</option>
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>Need a Liftgate</td>
                      <td style={{ paddingLeft: "20px" }}>
                        <FormControl required name="needALiftgate" as="select">
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </FormControl>
                      </td>
                    </tr>

                    <tr>
                      <td>Hours Available</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="availableHoursStart" inline as="select">
                          {Array.from({ length: 24 }, (_, k) => (
                            <option value={k + ":00"}>{k + ":00"}</option>
                          ))}
                        </FormControl>
                        <span style={{ margin: "0 15px 0 15px" }}>TO</span>
                        <FormControl required name="availableHoursEnd" inline as="select">
                          {Array.from({ length: 24 }, (_, k) => (
                            <option value={k + ":00"}>{k + ":00"}</option>
                          ))}
                        </FormControl>
                      </td>
                    </tr>
                    <tr>
                      <td>Date Available</td>
                      <td style={{ paddingLeft: "20px" }}>
                        {" "}
                        <FormControl required name="availableDate" className="dash_table_inputs" type="date" />
                      </td>
                    </tr>
                  </Fragment>
                )}
              </tbody>
            </Table>
            {this.props.isSendingEmail === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Send Email
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalEmailVendor;

import { apiBaseUrlLocal } from "./AppConfig";

export const getSources = (admin_data, callback) => {
  fetch(apiBaseUrlLocal + "admin/stats/sources", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      callback(result);
    })
    .catch((err) => {
      callback(false);
    });

  // .then(async (response) => { const sources = await response.json() })
  // .then((sources) => {
  //   callback(sources);
  // })
  // .catch((err) => {
  //   console.log(`Error occurred!`);
  //   console.log(err);
  //   callback(false);
  // });
};

export const getUsers = (admin_data, callback) => {
  fetch(apiBaseUrlLocal + "admin/users/list", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  })
    .then((response) => response.json())
    .then((users) => {
      callback(users);
    })
    .catch((err) => {
      callback(false);
    });
};

export const fetchTeamList = async (admin_data) => {
  return new Promise((resolve, reject) => {
    fetch(apiBaseUrlLocal + "admin/teams/list", {
      method: "POST",
      body: new URLSearchParams({
        user_id: admin_data.admin_id,
        app_token: admin_data.token,
      }),
    })
      .then((response) => response.json())
      .then((teams) => {
        resolve(teams);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const getRawStats = (
  admin_data,
  start_date,
  end_date,
  sources,
  users,
  teams,
  teams_or_users,
  exclude_duplicates,
  callback
) => {
  fetch(apiBaseUrlLocal + "admin/stats/raw", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      start_date: start_date,
      end_date: end_date,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
      users: users !== "" && users !== null ? JSON.stringify(users.map((user) => user.value)) : "",
      teams: teams !== "" && teams !== null ? JSON.stringify(teams.map((team) => team.value)) : "",
      teams_or_users: teams_or_users,
      exclude_duplicates: exclude_duplicates,
    }),
  })
    .then((response) => response.json())
    .then((statsData) => {
      callback(statsData);
    })
    .catch((err) => {
      callback(false);
    });
};

export const getStats = (
  admin_data,
  start_date,
  end_date,
  sources,
  users,
  teams,
  teams_or_users,
  exclude_duplicates,
  callback
) => {
  fetch(apiBaseUrlLocal + "admin/stats/history", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      start_date: start_date,
      end_date: end_date,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
      users: users !== "" && users !== null ? JSON.stringify(users.map((user) => user.value)) : "",
      teams: teams !== "" && teams !== null ? JSON.stringify(teams.map((team) => team.value)) : "",
      teams_or_users: teams_or_users,
      exclude_duplicates: exclude_duplicates,
    }),
  })
    .then((response) => response.json())
    .then((statsData) => {
      callback(statsData);
    })
    .catch((err) => {
      callback(false);
    });
};

export const getTimeSeries = (
  admin_data,
  start_date,
  end_date,
  sources,
  users,
  teams,
  teams_or_users,
  exclude_duplicates,
  frequency,
  callback
) => {
  fetch(`${apiBaseUrlLocal}admin/stats/by_dt?frequency=${frequency}`, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      start_date: start_date,
      end_date: end_date,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
      users: users !== "" && users !== null ? JSON.stringify(users.map((user) => user.value)) : "",
      teams: teams !== "" && teams !== null ? JSON.stringify(teams.map((team) => team.value)) : "",
      teams_or_users: teams_or_users,
      exclude_duplicates: exclude_duplicates,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      callback(result);
    })
    .catch((err) => {
      callback(false);
    });
};

export const updateMaxProfitAPI = async (admin_data, max_profit) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/maxprofit", {
    method: "PUT",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      max_profit: max_profit,
    }),
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const getModCount = (
  admin_data,
  start_date,
  end_date,
  sources,
  users,
  teams,
  teams_or_users,
  exclude_duplicates,
  callback
) => {
  fetch(apiBaseUrlLocal + "admin/stats/module_count", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      start_date: start_date,
      end_date: end_date,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
      users: users !== "" && users !== null ? JSON.stringify(users.map((user) => user.value)) : "",
      teams: teams !== "" && teams !== null ? JSON.stringify(teams.map((team) => team.value)) : "",
      teams_or_users: teams_or_users,
      exclude_duplicates: exclude_duplicates,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      callback(result);
    })
    .catch((err) => {
      callback(false);
    });
};

export const getStatsCount = (admin_data, sources, users, teams, teams_or_users, exclude_duplicates, callback) => {
  fetch(apiBaseUrlLocal + "admin/stats/scans_count", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
      users: users !== "" && users !== null ? JSON.stringify(users.map((user) => user.value)) : "",
      teams: teams !== "" && teams !== null ? JSON.stringify(teams.map((team) => team.value)) : "",
      teams_or_users: teams_or_users,
      exclude_duplicates: exclude_duplicates,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      callback(result);
    })
    .catch((err) => {
      callback(false);
    });
};

export const getStatsRejects = (admin_data, sources, users, teams, teams_or_users, exclude_duplicates, callback) => {
  fetch(apiBaseUrlLocal + "admin/stats/scans_rejects", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      sources: sources !== "" && sources !== null ? JSON.stringify(sources.map((source) => source.value)) : "",
      users: users !== "" && users !== null ? JSON.stringify(users.map((user) => user.value)) : "",
      teams: teams !== "" && teams !== null ? JSON.stringify(teams.map((team) => team.value)) : "",
      teams_or_users: teams_or_users,
      exclude_duplicates: exclude_duplicates,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      callback(result);
    })
    .catch((err) => {
      callback(false);
    });
};

import React, { Component } from "react";
import { FormControl, InputGroup, Button, Spinner } from "react-bootstrap";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { siteColors } from "../../includes/AppConfig";

class ColorTextPicker extends Component {
  state = {
    currentColor: "",
    currentDisplayText: "",
  };

  componentDidMount() {
    this.setState({ currentColor: this.props.customColor, currentDisplayText: this.props.customText });
  }

  render() {
    // const siteColors = [
    //   "#00743F",
    //   "#F4874B",
    //   "#6A8A82",
    //   "#A37C27",
    //   "#72A2C0",
    //   "#0D3C55",
    //   "#1ABC9C",
    //   "#040C0E",
    //   "#0444BF",
    //   "#A7414A",
    //   "#6465A5",
    //   "#F20050",
    // ];
    const ddOptions = siteColors.map((sc) => {
      return { value: sc, label: <div style={{ width: "20px", height: "20px", background: `${sc}` }}></div> };
    });
    // {
    //   "#00743F": "FunGreen",
    //   "#F4874B": "Jaffa",
    //   "#6A8A82": "Blue Smoke",
    //   "#A37C27": "Luxor Gold",
    //   "#72A2C0": "Glacier",
    //   "#0D3C55": "Tarawera",
    //   "#1ABC9C": "Mountain",
    //   "#040C0E": "Black Bean",
    //   "#0444BF": "Cobalt",
    //   "#A7414A": "Apple",
    //   "#6465A5": "Scampi",
    //   "#F20050": "Red Ribbon",
    // };

    return (
      <React.Fragment>
        <div>
          <div style={{ fontSize: "15px", fontWeight: "500" }}>SET CUSTOM COLOR & TEXT DISPLAY</div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <InputGroup id="ig_asin" style={{ maxWidth: "400px" }}>
            <InputGroup.Prepend>
              <Dropdown
                options={ddOptions}
                onChange={(col) => {
                  this.setState({ currentColor: col.value });
                }}
                value={this.state.currentColor !== "" ? this.state.currentColor : "#00743F"}
              />
            </InputGroup.Prepend>
            <FormControl
              value={this.state.currentDisplayText}
              onChange={(e) => {
                this.setState({ currentDisplayText: e.target.value });
              }}
              style={{ borderRadius: "0" }}
              autoComplete="off"
              placeholder="Display Text"
              aria-describedby="basic-addon1"
            />
            <InputGroup.Append>
              <Button
                onClick={() => {
                  this.props.updateCustomTextColor(this.state.currentColor, this.state.currentDisplayText);
                }}
              >
                {this.props.updatingCustomTextColor === true ? (
                  <Spinner style={{ width: "20px", height: "20px" }} animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </React.Fragment>
    );
  }
}

export default ColorTextPicker;

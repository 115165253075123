import React, { Component } from "react";
import TopMenu from "../components/TopMenu";
import { Row, Col, Container, Button } from "react-bootstrap";
import ModalInvite from "../components/Others/ModalInvite";
import { sendInvitation } from "../includes/HelperInvite";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class Invite extends Component {
  state = {
    showInviteModal: false,
    admin_data: cookies.get("admin_data"),
    isInvitingNewUser: false,
    inviteList: [],
    isLoadingInviteList: false,
  };

  constructor() {
    super();
    this.onFormSubmitInviteUser = this.onFormSubmitInviteUser.bind(this);
  }

  componentDidMount() {
    this.getPendingInvites();
  }

  async getPendingInvites() {}

  async onFormSubmitInviteUser(evt) {
    console.log("Sending new invite");
    evt.preventDefault();

    const teamEmail = evt.target.elements.teamEmail.value;
    const teamName = evt.target.elements.teamName.value;

    if (teamEmail !== "" && teamName !== "") {
      this.setState({ isInvitingNewUser: true });
      // Call API to add trigger
      const response = await sendInvitation(teamEmail, teamName, this.state.admin_data);
      response !== false && response.status === "success" && this.getAudio();
      this.setState({
        showInviteModal: false,
        isInvitingNewUser: false,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <TopMenu history={this.props.history} activeKey="/sounds" adminData={this.state.admin_data} />

        <ModalInvite
          showInviteModal={this.state.showInviteModal}
          isInvitingNewUser={this.state.isInvitingNewUser}
          onChangeAudioFile={this.onChangeAudioFile}
          handleClose={() => this.setState({ showInviteModal: false })}
          onFormSubmitInviteUser={this.onFormSubmitInviteUser}
        />

        <Container fluid={true} style={{ marginTop: "15px" }}>
          <h2>Pending Invites</h2>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              <Button
                className="actionButtonLessPadding"
                style={{ marginLeft: "0px" }}
                variant="success"
                onClick={() => this.setState({ showInviteModal: true })}
              >
                Invite New User
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12}>
              {/* <Table bordered className="table_triggers">
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Email Address</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.isLoadingInviteList === false ? (
                    this.state.inviteList.map((invite, _index) => {
                      return (
                        <tr>
                          <td>{audioRecord.name}</td>
                          <td>
                            <div style={{ float: "left" }}>
                              <PlayAudio width={35} url={this.state.soundApiURL + audioRecord.file_path} />
                            </div>
                            <div style={{ marginLeft: "50px", paddingTop: "3px" }}>
                              <Button
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="danger"
                                onClick={() => this.onHandleDeleteAudio(audioRecord, _index)}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table> */}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Invite;

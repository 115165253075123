import { apiBaseUrlLocal } from "./AppConfig";
import axios from "axios";

export const submitNewAudio = async (soundName, soundFile, admin_data) => {
  const data = new FormData();
  data.append("name", soundName);
  data.append("audio", soundFile);
  data.append("user_id", admin_data.admin_id);
  data.append("app_token", admin_data.token);
  try {
    let res = await axios.post(apiBaseUrlLocal + "admin/sounds/", data);
    const dataRes = await res.data;
    return dataRes;
  } catch (err) {
    return false;
  }
};

export const getSounds = async (admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "audio/list");
  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data;
  } else return false;
};

export const deleteSound = async (sound, admin_data) => {
  const response = await fetch(apiBaseUrlLocal + "admin/sounds/" + sound.id, {
    method: "DELETE",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data;
  } else return false;
};

import React, { Component, Fragment } from "react";
import { Row, Col, Form, Modal, Button, InputGroup } from "react-bootstrap";

class ModalAddTrigger extends Component {
  state = {
    isCeiling1Enabled: false,
    isCeiling2Enabled: false,
    isCeiling3Enabled: false,
    isCeilingKeepaEnabled: false,
    isMMEnabled: false,
  };
  constructor() {
    super();
    this.handleC1Change = this.handleC1Change.bind(this);
    this.handleC2Change = this.handleC2Change.bind(this);
    this.handleC3Change = this.handleC3Change.bind(this);
    this.handleCKeepaChange = this.handleCKeepaChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleC1Change(evt) {
    this.setState({ isCeiling1Enabled: evt.target.checked });
  }
  handleC2Change(evt) {
    this.setState({ isCeiling2Enabled: evt.target.checked });
  }
  handleC3Change(evt) {
    this.setState({ isCeiling3Enabled: evt.target.checked });
  }
  handleCKeepaChange(evt) {
    this.setState({ isCeilingKeepaEnabled: evt.target.checked });
  }

  handleOpen() {
    this.setState({
      isCeiling1Enabled: false,
      isCeiling2Enabled: false,
      isCeiling3Enabled: false,
      isCeilingKeepaEnabled: false,
      isMMEnabled: false,
    });
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showAddModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.props.onFormSubmitAddTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              placeholder="Enter Trigger Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formMinScore">
                        <Form.Label>Min eScore</Form.Label>
                        <Form.Control required type="number" step="0.01" name="minEscore" placeholder="Min eScore" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formMaxScore">
                        <Form.Label>Max eScore</Form.Label>
                        <Form.Control required type="number" step="0.01" name="maxEscore" placeholder="Max eScore" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formMinRank">
                        <Form.Label>Min SalesRank</Form.Label>
                        <Form.Control required type="number" step="0.01" name="minRank" placeholder="Min SalesRank" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formMaxRank">
                        <Form.Label>Max SalesRank</Form.Label>
                        <Form.Control required type="number" step="0.01" name="maxRank" placeholder="Max SalesRank" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formFbaSlot">
                        <Form.Label>FBA Slot</Form.Label>

                        <Form.Control required as="select" name="fbaSlot">
                          <option value="-1">SKIP</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Form.Control>

                        {/* <Form.Control
                          required
                          type="number"
                          step="0.01"
                          name="fbaSlot"
                          placeholder="FBA Slot"
                        />
                        <Form.Text className="text-muted">
                          Enter <strong>-1</strong> to disable.
                        </Form.Text> */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formUsedSlot">
                        <Form.Label>Used Slot</Form.Label>
                        <Form.Control required as="select" name="usedSlot">
                          <option value="-1">SKIP</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">10</option>
                          <option value="7">15</option>
                        </Form.Control>
                        {/* <Form.Control required type="number" step="0.01" name="usedSlot" placeholder="Used Slot" />
                        <Form.Text className="text-muted">
                          Enter <strong>-1</strong> to disable.
                        </Form.Text> */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formAmzPer">
                        <Form.Label>Amazon Off %</Form.Label>
                        {/* <Form.Control required type="number" step="0.01" name="amzOffPer" placeholder="Amazon Off %" /> */}
                        <InputGroup className="mb-3">
                          <Form.Control required type="number" step="0.01" name="amzOffPer" />
                          <InputGroup.Append>
                            <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formTargetProfit">
                        <Form.Label>Target Profit</Form.Label>

                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{ background: "#007bff", color: "#fff", fontWeight: "bold" }}
                              id="per-symbol"
                            >
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            style={{ background: "#007bff", color: "#fff", fontWeight: "bold" }}
                            required
                            type="number"
                            step="0.01"
                            name="targetProfit"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ height: "50px" }}>
                    <Col style={{ paddingTop: "8px" }} md={3}>
                      <Form.Group controlId="formIsCeiling1Compare">
                        <Form.Check
                          onChange={this.handleC1Change}
                          type="switch"
                          name="isCeiling1Enabled"
                          id="is-ceiling-1"
                          label="Ceiling 1?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {this.state.isCeiling1Enabled && (
                        <Form.Group controlId="formCeiling1Options">
                          <Form.Control as="select" name="ceiling1Options">
                            <option>...</option>
                            <option>Lowest New Price</option>
                            <option>New Buy Box</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={5}>
                      {this.state.isCeiling1Enabled && (
                        <InputGroup>
                          <Form.Control required type="number" step="0.01" name="ceiling1Discount" />
                          <InputGroup.Append>
                            <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ height: "50px" }}>
                    <Col style={{ paddingTop: "8px" }} md={2}>
                      <Form.Group controlId="formIsCeiling3Compare">
                        <Form.Check
                          onChange={this.handleC3Change}
                          type="switch"
                          name="isCeiling3Enabled"
                          id="is-ceiling-3"
                          label="PrimeLess?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {this.state.isCeiling3Enabled && (
                        <Form.Group controlId="formCeiling3Options">
                          <Form.Control style={{ fontSize: "13px" }} as="select" name="ceiling3Options">
                            <option>...</option>
                            <option>Lowest Used Offer</option>
                            <option>2nd Lowest Used Offer</option>
                            <option>3rd Lowest Used Offer</option>
                            <option>Average of 3 Used Offers</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={3}>
                      {this.state.isCeiling3Enabled && (
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling3BumpUpDollars"
                            placeholder="Bump$"
                          />
                        </InputGroup>
                      )}
                    </Col>
                    <Col md={3}>
                      {this.state.isCeiling3Enabled && (
                        <InputGroup>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling3BumpUpPercentage"
                            placeholder="Bump%"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ height: "50px" }}>
                    <Col style={{ paddingTop: "8px", paddingRight: "0" }} md={2}>
                      <Form.Group controlId="formIsCeiling2Compare">
                        <Form.Check
                          onChange={this.handleC2Change}
                          type="switch"
                          name="isCeiling2Enabled"
                          id="is-ceiling-2"
                          label="Ceiling 2?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {this.state.isCeiling2Enabled && (
                        <Form.Group controlId="formCeiling2Options">
                          <Form.Control style={{ fontSize: "13px" }} as="select" name="ceiling2Options">
                            <option>...</option>
                            <option>Lowest Used Offer</option>
                            <option>2nd Lowest Used Offer</option>
                            <option>3rd Lowest Used Offer</option>
                            <option>Average of 3 Used Offers</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={3}>
                      {this.state.isCeiling2Enabled && (
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            placeholder="Bump$"
                            required
                            type="number"
                            step="0.01"
                            name="ceiling2BumpUpDollars"
                          />
                        </InputGroup>
                      )}
                    </Col>
                    <Col md={3}>
                      {this.state.isCeiling2Enabled && (
                        <InputGroup>
                          <Form.Control
                            placeholder="Bump%"
                            required
                            type="number"
                            step="0.01"
                            name="ceiling2BumpUpPercentage"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>

                  {this.props.premiumLevel === 1 &&
                    this.props.domainName !== "admin.emptyshelves.com" &&
                    this.props.domainName !== "admin.bookteriors.com" && (
                      <Fragment>
                        <Row style={{ height: "50px" }}>
                          <Col style={{ paddingTop: "8px", paddingRight: "10" }} md={3}>
                            <Form.Group controlId="formIsCeilingKeepaCompare">
                              <Form.Check
                                onChange={this.handleCKeepaChange}
                                type="switch"
                                name="isCeilingKeepaEnabled"
                                id="is-ceiling-keepa"
                                label="Keepa Ceiling?"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            {this.state.isCeilingKeepaEnabled && (
                              <Form.Group controlId="formCeiling2Options">
                                <Form.Control style={{ fontSize: "13px" }} as="select" name="ceilingKeepaOptions">
                                  <option>...</option>
                                  <option>Median</option>
                                  <option>Percentile 80</option>
                                  <option>Percentile 20</option>
                                </Form.Control>
                              </Form.Group>
                            )}
                          </Col>
                          <Col md={3}>
                            {this.state.isCeilingKeepaEnabled && (
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  placeholder="Bump$"
                                  required
                                  type="number"
                                  step="0.01"
                                  name="ceilingKeepaBumpUpDollars"
                                />
                              </InputGroup>
                            )}
                          </Col>
                          <Col md={3}>
                            {this.state.isCeilingKeepaEnabled && (
                              <InputGroup>
                                <Form.Control
                                  placeholder="Bump%"
                                  required
                                  type="number"
                                  step="0.01"
                                  name="ceilingKeepaBumpUpPercentage"
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>

                        <Row style={{ height: "50px" }}>
                          <Col md={5} style={{ paddingTop: "8px" }}>
                            <Form.Group controlId="formIsMMCompare">
                              <Form.Check
                                onChange={(evt) => {
                                  this.setState({ isMMEnabled: evt.target.checked });
                                }}
                                type="switch"
                                name="isMMEnabled"
                                id="is-mm"
                                label="Enable Marketplace Multiplier?"
                              />
                            </Form.Group>
                          </Col>

                          <Col md={7}>
                            {this.state.isMMEnabled && (
                              <InputGroup>
                                <Form.Control required type="number" step="0.01" name="mm_option" />
                                <InputGroup.Append>
                                  <InputGroup.Text id="per-symbol">X Target Profit</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    )}

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formIsBBCompare">
                        <Form.Check type="switch" name="isBBCompare" id="bb-compare" label="BB Compare?" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Check type="switch" name="isAlwaysReject" id="always-reject" label="Always Reject?" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddTrigger;

import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, InputGroup } from "react-bootstrap";

class ModalEditTrigger extends Component {
  state = {
    isCeiling1Enabled: false,
    isCeiling2Enabled: false,
    isCeiling3Enabled: false,
    isCeilingKeepaEnabled: false,
  };

  constructor() {
    super();
    this.handleC1Change = this.handleC1Change.bind(this);
    this.handleC2Change = this.handleC2Change.bind(this);
    this.handleC3Change = this.handleC3Change.bind(this);
    this.handleCKeepaChange = this.handleCKeepaChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.onFormSubmitEditTrigger = this.onFormSubmitEditTrigger.bind(this);
  }

  handleC1Change(evt) {
    this.setState({ isCeiling1Enabled: evt.target.checked });
  }

  handleC2Change(evt) {
    this.setState({ isCeiling2Enabled: evt.target.checked });
  }

  handleC3Change(evt) {
    this.setState({ isCeiling3Enabled: evt.target.checked });
  }

  handleCKeepaChange(evt) {
    this.setState({ isCeilingKeepaEnabled: evt.target.checked });
  }

  handleOpen() {
    if ("jsonData" in this.props.editTrigger)
      this.setState({
        isCeiling1Enabled: this.props.editTrigger.jsonData.ceiling_1_option,
        isCeiling2Enabled: this.props.editTrigger.jsonData.ceiling_2_option,
        isCeiling3Enabled: this.props.editTrigger.jsonData.ceiling_3_option,
        isCeilingKeepaEnabled:
          "ceiling_keepa_option" in this.props.editTrigger.jsonData
            ? this.props.editTrigger.jsonData.ceiling_keepa_option
            : "",
      });
  }

  onFormSubmitEditTrigger(event) {
    event.preventDefault();

    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const isCeiling1Options = event.target.elements.isCeiling1Enabled.checked;
    const isCeilingKeepaOptions =
      "isCeilingKeepaEnabled" in event.target.elements ? event.target.elements.isCeilingKeepaEnabled.checked : false;

    const triggerJson = {
      min_escore: event.target.elements.minEscore.value,
      max_escore: event.target.elements.maxEscore.value,
      min_rank: event.target.elements.minRank.value,
      max_rank: event.target.elements.maxRank.value,
      used_slot: event.target.elements.usedSlot.value,
      always_reject: event.target.elements.isAlwaysReject.checked,
      amz_off_per: event.target.elements.amzOffPer.value,
      target_profit: event.target.elements.targetProfit.value,
      ceiling_1_option: isCeiling1Options ? event.target.elements.ceiling1Options.value : "",
      ceiling_1_discount: isCeiling1Options ? event.target.elements.ceiling1Discount.value : 0,
      ceiling_keepa_option: isCeilingKeepaOptions ? event.target.elements.ceilingKeepaOptions.value : "",
      ceiling_keepa_bump_up_dollars: isCeilingKeepaOptions ? event.target.elements.ceilingKeepaBumpUpDollars.value : 0,
      ceiling_keepa_bump_up_percentage: isCeilingKeepaOptions
        ? event.target.elements.ceilingKeepaBumpUpPercentage.value
        : 0,
    };

    this.props.onFormSubmitEditTrigger(triggerName, triggerJson);
  }

  render() {
    return (
      <Modal
        onEntered={this.handleOpen}
        show={this.props.showEditModal}
        onHide={this.props.handleClose}
        dialogClassName="editModal"
      >
        <Form onSubmit={this.onFormSubmitEditTrigger}>
          <Modal.Header closeButton>
            <Form.Control
              required
              type="text"
              name="triggerName"
              className="input-text-trigger-name"
              defaultValue={this.props.editTrigger.TriggerName}
              placeholder="Enter Trigger Name Here"
            />
          </Modal.Header>
          <Modal.Body>
            {"jsonData" in this.props.editTrigger && (
              <div style={{ fontSize: "13px", fontWeight: "500" }}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={3}>
                        <Form.Group controlId="formMinScore">
                          <Form.Label>Min eScore</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="minEscore"
                            defaultValue={this.props.editTrigger.jsonData.min_escore}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formMaxScore">
                          <Form.Label>Max eScore</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="maxEscore"
                            defaultValue={this.props.editTrigger.jsonData.max_escore}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formMinRank">
                          <Form.Label>Min SalesRank</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="minRank"
                            defaultValue={this.props.editTrigger.jsonData.min_rank}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId="formMaxRank">
                          <Form.Label>Max SalesRank</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="maxRank"
                            defaultValue={this.props.editTrigger.jsonData.max_rank}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Used Slot</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="usedSlot"
                            placeholder="Used Slot"
                            defaultValue={this.props.editTrigger.jsonData.used_slot}
                          />
                          <Form.Text className="text-muted">
                            Enter <strong>-1</strong> to disable.
                          </Form.Text>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group controlId="formAmzPer">
                          <Form.Label>Amazon Off %</Form.Label>

                          <InputGroup className="mb-3">
                            <Form.Control
                              required
                              type="number"
                              step="0.01"
                              name="amzOffPer"
                              placeholder="Amazon Off %"
                              defaultValue={this.props.editTrigger.jsonData.amz_off_per}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group controlId="formTargetProfit">
                          <Form.Label>Target Profit</Form.Label>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                style={{ background: "#007bff", color: "#fff", fontWeight: "bold" }}
                                id="per-symbol"
                              >
                                $
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              required
                              type="number"
                              step="0.01"
                              name="targetProfit"
                              placeholder="Target Profit ($)"
                              style={{ background: "#007bff", color: "#fff", fontWeight: "bold" }}
                              defaultValue={this.props.editTrigger.jsonData.target_profit}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ height: "50px" }}>
                      <Col style={{ paddingTop: "8px" }} md={3}>
                        <Form.Group controlId="formIsCeiling1Compare">
                          <Form.Check
                            onChange={this.handleC1Change}
                            type="switch"
                            name="isCeiling1Enabled"
                            id="is-ceiling-1"
                            defaultChecked={
                              "ceiling_1_option" in this.props.editTrigger.jsonData &&
                              this.props.editTrigger.jsonData.ceiling_1_option !== ""
                            }
                            label="Ceiling 1?"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        {this.state.isCeiling1Enabled && (
                          <Form.Group controlId="formCeiling1Options">
                            <Form.Control
                              as="select"
                              name="ceiling1Options"
                              defaultValue={this.props.editTrigger.jsonData.ceiling_1_option}
                            >
                              <option value="">...</option>
                              <option value="Lowest New Price">Lowest New Price</option>
                              <option value="New Buy Box">New Buy Box</option>
                            </Form.Control>
                          </Form.Group>
                        )}
                      </Col>
                      <Col md={5}>
                        {this.state.isCeiling1Enabled && (
                          <InputGroup>
                            <Form.Control
                              required
                              type="number"
                              step="0.01"
                              defaultValue={this.props.editTrigger.jsonData.ceiling_1_discount}
                              name="ceiling1Discount"
                              placeholder="Ceiling 1 Discount %"
                            />
                            <InputGroup.Append>
                              <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        )}
                      </Col>
                    </Row>

                    {this.props.premiumLevel === 1 && (
                      <Row style={{ height: "50px" }}>
                        <Col style={{ paddingTop: "8px", paddingRight: "10" }} md={3}>
                          <Form.Group controlId="formIsCeilingKeepaCompare">
                            <Form.Check
                              onChange={this.handleCKeepaChange}
                              type="switch"
                              name="isCeilingKeepaEnabled"
                              id="is-ceiling-keepa"
                              label="Keepa Ceiling?"
                              defaultChecked={
                                "ceiling_keepa_option" in this.props.editTrigger.jsonData &&
                                this.props.editTrigger.jsonData.ceiling_2_option !== ""
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          {this.state.isCeilingKeepaEnabled && (
                            <Form.Group controlId="formCeiling2Options">
                              <Form.Control
                                style={{ fontSize: "13px" }}
                                as="select"
                                name="ceilingKeepaOptions"
                                defaultValue={
                                  "ceiling_keepa_option" in this.props.editTrigger.jsonData
                                    ? this.props.editTrigger.jsonData.ceiling_keepa_option
                                    : ""
                                }
                              >
                                <option>...</option>
                                <option>Median</option>
                                <option>Percentile 80</option>
                                <option>Percentile 20</option>
                              </Form.Control>
                            </Form.Group>
                          )}
                        </Col>
                        <Col md={3}>
                          {this.state.isCeilingKeepaEnabled && (
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <Form.Control
                                placeholder="Bump$"
                                required
                                type="number"
                                step="0.01"
                                name="ceilingKeepaBumpUpDollars"
                                defaultValue={
                                  "ceiling_keepa_bump_up_dollars" in this.props.editTrigger.jsonData
                                    ? this.props.editTrigger.jsonData.ceiling_keepa_bump_up_dollars
                                    : ""
                                }
                              />
                            </InputGroup>
                          )}
                        </Col>
                        <Col md={3}>
                          {this.state.isCeilingKeepaEnabled && (
                            <InputGroup>
                              <Form.Control
                                placeholder="Bump%"
                                required
                                type="number"
                                step="0.01"
                                name="ceilingKeepaBumpUpPercentage"
                                defaultValue={
                                  "ceiling_keepa_bump_up_percentage" in this.props.editTrigger.jsonData
                                    ? this.props.editTrigger.jsonData.ceiling_keepa_bump_up_percentage
                                    : ""
                                }
                              />
                              <InputGroup.Append>
                                <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          )}
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col md={6}>
                        <Form.Check
                          type="switch"
                          name="isAlwaysReject"
                          id="always-reject"
                          label="Always Reject?"
                          defaultChecked={this.props.editTrigger.jsonData.always_reject}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalEditTrigger;
